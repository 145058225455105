import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
// import axios from "axios";
// import Swal from "sweetalert2"
// material 
import { alpha } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { Context } from "src/contexts/UserContext";
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { useTheme } from "@material-ui/core/styles";
import { FaCloudSun, FaCloudMoon, FaSun } from "react-icons/fa";
import closeFill from '@iconify/icons-eva/close-fill';
import options2Fill from '@iconify/icons-eva/options-2-fill';
import firebase from "firebase/app";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
//constants
import { LOCAL_STORAGE_USER_AUTHENTICATED, LOCAL_STORAGE_USER_VERIFIED } from "../../constant/LoginConstants"

// ----------------------------------------------------------------------

const MENU_OPTIONS = [

  // ,
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_DASHBOARD.user.profile
  // },
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: PATH_DASHBOARD.user.account
  // }
];

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  isOpenSettingbar: PropTypes.bool,
  onOpenSettingbar: PropTypes.func,
  onCloseSettingbar: PropTypes.func,
};

export default function AccountPopover({ isOpen, isOpenSettingbar, onOpenSettingbar, onCloseSettingbar }) {

  // console.log("AccountPopover - isOpenSettingbar : " + isOpenSettingbar);

  const theme = useTheme();

  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const { userOb: userObject } = useContext(Context);

  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    }
  }, [isOpen, isOpenSettingbar]);

  const handleLogout = async () => {
    try {
      process.env.REACT_APP_ENVIRONMENT == "production" ? (() => { })() : console.log("handleLogout");

      localStorage.setItem(LOCAL_STORAGE_USER_AUTHENTICATED, false);
      localStorage.setItem(LOCAL_STORAGE_USER_VERIFIED, false);
      await firebase.auth().signOut();

      navigate("/auth/login");

      // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("navigation done");
      if (isMountedRef.current) {
        handleClose();
        // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("close handled");
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const getGreetingMsg = () => {

    // console.log("getGreetingMsg", JSON.stringify(userObject));

    const day = new Date();
    const hr = day.getHours();
    if (hr < 12) {
      return (
        <>
          Morning <FaCloudSun style={{ marginLeft: "4px" }} />{" "}
        </>
      );
    } else if (hr >= 12 && hr <= 17) {
      return (
        <>
          Afternoon <FaSun style={{ marginLeft: "4px" }} />{" "}
        </>
      );
    } else if (hr >= 17 && hr <= 24) {
      return (
        <>
          Evening
          <FaCloudMoon style={{ marginLeft: "4px" }} />
        </>
      );
    }
  };

  const handleSettingmenuOpen = () => {
    setOpen(false);
    if (isOpenSettingbar) {
      onCloseSettingbar();
    } else {
      onOpenSettingbar();
    }
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className="clickable"
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} style={{ width: '250px', maxWidth:"250px", display:'flex', flexDirection:'column', gap:'0px' }}>
        
        {userObject != null &&
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <div style={{ gap: "0px", marginRight: "10px" }}>
              <p style={{ color: `${theme.palette.text.disabled}`, fontSize: "80%", marginBottom: "0px", padding: "0px" }}>Good {getGreetingMsg()} </p>
              <p style={{ color: `${theme.palette.text.secondary}`, marginTop: "2.5px", padding: "0px" }}>{userObject != null && userObject?.fullName != null ? userObject?.fullName : ""} </p>
              <p style={{ color: `${theme.palette.text.disabled}`, marginTop: "2.5px", padding: "0px" }}>{userObject != null && userObject?.email != null ? userObject?.email : ""} </p>
            </div>
          </Box>
        }

        <Divider sx={{ my: 1 }} />

        <div style={{width:"100%", padding:"10px", display:'flex', flexDirection:'column', gap:"10px", minWidth:"100%"}}>
          <Button
            fullWidth style={{ padding: "5px 10px", minHeight:"45px"}} color="inherit" variant="outlined" 
            onClick={handleSettingmenuOpen}>
            <p>{isOpenSettingbar ? "Close settings" : "Open Settings"}</p>
            <Tooltip title="Settings">
              <MIconButton
                style={{
                  color: `${theme.palette.text.secondary}`,
                  padding: "0px",
                  marginLeft: "10px",
                  width: "30px",
                  height: "30px",
                  transition: (theme) => theme.transitions.create('all'),
                  '&:hover': { color: 'text.disabled', bgcolor: 'transparent' }
                }}
              >
                <Icon icon={isOpenSettingbar ? closeFill : options2Fill} style={{ padding: "0px", width: "40px", height: "40px", padding: "5px" }} />
              </MIconButton>
            </Tooltip>
          </Button>
        </div>
        {userObject && userObject?.dashboardVersion ? <div style={{display:'flex',justifyContent:'flex-end',margin:'.4rem 1rem',fontSize:'.8rem'}}>
            v{userObject?.dashboardVersion}
          </div>:null }
         
        <div style={{width:"100%", display:'flex', flexDirection:'column', gap:"10px", minWidth:"100%"}}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />
              {option.label}
            </MenuItem>
          ))}
        </div>

        <Divider/>

        <Box style={{ padding: "5px 10px"}}>
          <Button fullWidth color="inherit" variant="standard" onClick={handleLogout}>
            Logout
          </Button>
        </Box>

      </MenuPopover>
    </>
  );
}

